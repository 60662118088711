import router from '@/router'
import RouteNames from '@/util/route_names'
import MitarbeiterDetailsMemcommonLogic from '../memcommon/mitarbeiter_details_memcommon_logic'

export default class MitarbeiterDetailsLogic extends MitarbeiterDetailsMemcommonLogic {
  bearbeiten() {
    router.push({
      name: RouteNames.SYSTEM.MITARBEITER_EDIT,
      params: { id: this.mitarbeiter.id },
    })
  }
}
