
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop, PropSync, Watch } from 'vue-property-decorator'
import { PreislisteProdukt } from '@/components/custom/preisliste'
import ToolbarButton from '../custom/ToolbarButton.vue'
import Expander from '../custom/Expander.vue'
import { v4 as uuidv4 } from 'uuid'
import Swapper from '@/util/swapper'

@Component({
  name: 'PreislisteProduktContainer',
  components: { ToolbarButton, Expander, PreislisteProduktContainer },
})
export default class PreislisteProduktContainer extends Vue {
  moveArtUp(i) {
    Swapper.instance.pushUp(this.produkt.arten, i)
  }

  moveArtDown(i) {
    Swapper.instance.pushDown(this.produkt.arten, i)
  }

  moveSubproduktUp(i) {
    Swapper.instance.pushUp(this.produkt.subprodukte, i)
  }

  moveSubproduktDown(i) {
    Swapper.instance.pushDown(this.produkt.subprodukte, i)
  }

  moveSubproduktOptionUp(i, j) {
    Swapper.instance.pushUp(this.produkt.subprodukte[i].optionen, j)
  }

  moveSubproduktOptionDown(i, j) {
    Swapper.instance.pushDown(this.produkt.subprodukte[i].optionen, j)
  }

  @Prop({ required: true }) produkt!: PreislisteProdukt
  //produktSync: PreislisteProdukt = null

  @Emit('produktEntfernen')
  produktEntfernen() {}

  @Emit('moveUp')
  moveUp() {}

  @Emit('moveDown')
  moveDown() {}

  moveExtraUp(index: number) {
    Swapper.instance.pushUp(this.produkt.extras, index)
  }

  moveExtraDown(index: number) {
    Swapper.instance.pushDown(this.produkt.extras, index)
  }

  moveAuswahlUp(index: number) {
    Swapper.instance.pushUp(this.produkt.auswahlen, index)
  }

  moveAuswahlDown(index: number) {
    Swapper.instance.pushDown(this.produkt.auswahlen, index)
  }

  moveAuswahlOptionUp(auswahlIndex: number, optionIndex: number) {
    Swapper.instance.pushUp(
      this.produkt.auswahlen[auswahlIndex].optionen,
      optionIndex
    )
  }

  moveAuswahlOptionDown(auswahlIndex: number, optionIndex: number) {
    Swapper.instance.pushDown(
      this.produkt.auswahlen[auswahlIndex].optionen,
      optionIndex
    )
  }

  @Prop({ default: uuidv4() })
  smartKeySync!: string

  mounted() {}

  randomkey() {
    return uuidv4()
  }
}
