
import Vue from 'vue'
import Component from 'vue-class-component'
import LinkBox from '@/components/custom/LinkBox.vue'
import MemberPortalLogic from '@/logic/member/member_portal_logic'

@Component({ components: { LinkBox } })
export default class MemberPortal extends Vue {
  logic?: MemberPortalLogic = null

  mounted() {
    this.logic = new MemberPortalLogic()
    this.logic.init()
  }

  navEigeneDaten() {
    this.logic.navEigeneDaten()
  }

  navKueche() {
    this.logic.navKueche()
  }

  navService() {
    this.logic.navService()
  }

  navBestellungen() {
    this.logic.navBestellungen()
  }

  navBestellverwaltung() {
    this.logic.navBestellverwaltung()
  }

  navBestellungAufnehmen() {
    this.logic.navBestellungAufnehmen()
  }

  navReservierungen() {
    this.logic.navReservierungen()
  }

  navFilialen() {
    this.logic.navFilialen()
  }

  navOeffnungszeiten() {
    this.logic.navOeffnungszeiten()
  }

  navInhaltsstoffe() {
    this.logic.navInhaltsstoffe()
  }

  navProdukte() {
    this.logic.navProdukte()
  }

  navVerfuegbarkeiten() {
    this.logic.navVerfuegbarkeiten()
  }

  navPreislisten() {
    this.logic.navPreislisten()
  }

  navTische() {
    this.logic.navTische()
  }

  navMitarbeiter() {
    this.logic.navMitarbeiter()
  }
}
