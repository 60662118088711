export default class Validator {
  static plzValidate = [
    (v: string) => {
      if(!!!v) return 'Bitte PLZ angeben'
      var pattern = /^\d{5}$/
      return pattern.test(v) ? true : 'Bitte gültige PLZ angeben'
    },
  ]
  
  static emailValidate = [
    (v: string) => {
      if(!!!v) return 'Bitte E-Mail angeben'
      var pattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g
      return pattern.test(v) ? true : 'Bitte gültige E-Mail angeben'
    },
  ]

  static usernameRules = [
    (v: string) => {
      if (!!!v) return 'Bitte Benutzername angeben'
      if (v.length < 5) {
        return 'Benutzername muss aus min. 5 Zeichen bestehen'
      }
      return true
    },
  ]

  static strongPasswordRules = [
    (v: string) => {
      if(!!!v) return 'Bitte Passwort angeben'
      var pattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,20}$/
    }
  ]
}