
import Vue from "vue";
import Component from "vue-class-component";
import PreislistenFilialeMemCommon from "../memcommon/PreislistenFilialeMemCommon.vue";
import PreislistenFilialeLogic from "@/logic/member/preislisten_filiale_logic";

@Component({ components: { PreislistenFilialeMemCommon } })
export default class PreislistenFiliale extends Vue {
  logic?: PreislistenFilialeLogic = null;

  mounted() {
    this.logic = new PreislistenFilialeLogic();
    this.logic.init(this.$route.params.filialeId);
  }
}
