import Vue from "vue";
import store from "@/store";

import VueRouter, { RouteConfig } from "vue-router";
import BetriebAnmeldenInfo from "@/components/rechtliches/BetriebAnmeldenInfo.vue";
import Homepage from "@/components/Homepage.vue";
import Impressum from "@/components/rechtliches/Impressum.vue";
import Datenschutz from "@/components/rechtliches/Datenschutz.vue";
import Agb from "@/components/rechtliches/Agb.vue";
import LoginMitarbeiter from "@/components/member/Login.vue";
import LoginSystem from "@/components/system/Login.vue";
import FilialeSelectMember from "@/components/member/FilialeSelect.vue";
import EigeneDaten from "@/components/member/EigeneDaten.vue";
import PasswortAendern from "@/components/member/PasswortAendern.vue";
import Mitarbeiter from "@/components/member/Mitarbeiter.vue";
import MitarbeiterNeu from "@/components/member/MitarbeiterNeu.vue";
import MitarbeiterDetails from "@/components/member/MitarbeiterDetails.vue";
import MitarbeiterEdit from "@/components/member/MitarbeiterEdit.vue";
import Kueche from "@/components/member/Kueche.vue";
import Service from "@/components/member/Service.vue";
import Reservierungen from "@/components/member/Reservierungen.vue";
import BestelllisteMember from "@/components/member/BestellverwaltungListe.vue";
import AktuelleBestellungen from "@/components/member/AktuelleBestellungen.vue";
import BestellungMember from "@/components/member/Bestellung.vue";
import Auswertung from "@/components/member/Auswertung.vue";
import Filialen from "@/components/member/Filialen.vue";
import FilialeDetails from "@/components/member/FilialeDetails.vue";
import FilialeEdit from "@/components/member/FilialeEdit.vue";
import FilialeNeu from "@/components/member/FilialeNeu.vue";
import FilialeLiefefergebietZuordnen from "@/components/member/FilialeLiefergebietZuordnen.vue";
import Produkte from "@/components/member/Produkte.vue";
import ProduktDetails from "@/components/member/ProduktDetails.vue";
import ProduktNeu from "@/components/member/ProduktNeu.vue";
import ProduktEdit from "@/components/member/ProduktEdit.vue";
import ProduktExtras from "@/components/member/ProduktExtras.vue";
import Ausverkauft from "@/components/member/Ausverkauft.vue";
import Basiskategorien from "@/components/member/Basiskategorie.vue";
import Inhaltsstoffe from "@/components/member/Inhaltsstoffe.vue";
import Preislisten from "@/components/member/Preislisten.vue";
import PreislistenFiliale from "@/components/member/PreislistenFiliale.vue";
import PreislisteDetails from "@/components/member/PreislisteDetails.vue";
//import PreislisteNeu from '@/components/memcommon/NewPreislisteMemCommon.vue'
import PreislisteFilialeNeu from "@/components/member/NewPreislisteFilialeNeu.vue";
import TageskarteNeu from "@/components/member/TageskarteNeu.vue";
import TageskarteEdit from "@/components/member/TageskarteEdit.vue";
import TageskarteDetails from "@/components/member/TageskarteDetails.vue";
import PreislisteEdit from "@/components/member/NewPreislisteEdit.vue";
import Kategorien from "@/components/member/Kategorien.vue";
import ProduktArten from "@/components/member/ProduktArten.vue";
import Oeffnungszeiten from "@/components/member/Oeffnungszeiten.vue";
import OeffnungszeitenFiliale from "@/components/member/OeffnungszeitenFiliale.vue";
import OeffnungszeitFiliale from "@/components/member/OeffnungszeitFiliale.vue";
import OeffnungszeitNeu from "@/components/member/OeffnungszeitNeu.vue";
import OeffnungszeitDetails from "@/components/member/OeffnungszeitDetails.vue";
import OeffnungszeitEdit from "@/components/member/OeffnungszeitEdit.vue";
import OeffnungszeitAusnahmenFiliale from "@/components/member/OeffnungszeitAusnahmenFiliale.vue";
import Tische from "@/components/member/Tische.vue";
import TischeFiliale from "@/components/member/TischeFiliale.vue";
import TischDetails from "@/components/member/TischDetails.vue";
import TischEdit from "@/components/member/TischEdit.vue";
import Shortcuts from "@/components/member/Shortcuts.vue";
import Lieferungen from "@/components/member/Lieferungen.vue";
import Standardpreise from "@/components/memcommon/Standardpreise.vue";
import BestellungAufnehmen from "@/components/member/BestellungAufnehmen.vue";
import RouteNames from "@/util/route_names";

import UserPage from "@/components/UserPage.vue";
import MemberPage from "@/components/MemberPage.vue";
import MemberPortal from "@/components/member/MemberPortal.vue";
import SystemPage from "@/components/SystemPage.vue";
import SystemPortal from "@/components/system/SystemPortal.vue";
import Betriebe from "@/components/system/Betriebe.vue";
import Setup from "@/components/system/Setup.vue";
import BetriebNeu from "@/components/system/BetriebNeu.vue";
import BetriebPage from "@/components/system/BetriebPage.vue";
import BetriebEdit from "@/components/system/BetriebEdit.vue";
import BetriebDetails from "@/components/system/BetriebDetails.vue";
import SystemBetriebAnfrageDetails from "@/components/system/BetriebAnfrageDetails.vue";
import SystemBetriebAnfragen from "@/components/system/BetriebAnfragen.vue";
import SystemFilialen from "@/components/system/Filialen.vue";
import SystemFilialeDetails from "@/components/system/FilialeDetails.vue";
import SystemFilialeNeu from "@/components/system/FilialeNeu.vue";
import SystemFilialeEdit from "@/components/system/FilialeEdit.vue";
import SystemMitarbeiter from "@/components/system/Mitarbeiter.vue";
import SystemMitarbeiterNeu from "@/components/system/MitarbeiterNeu.vue";
import SystemMitarbeiterEdit from "@/components/system/MitarbeiterEdit.vue";
import SystemMitarbeiterDetails from "@/components/system/MitarbeiterDetails.vue";
import SystemProdukte from "@/components/system/Produkte.vue";
import SystemProduktNeu from "@/components/system/ProduktNeu.vue";
import SystemProduktDetails from "@/components/system/ProduktDetails.vue";
import SystemProduktExtras from "@/components/system/ProduktExtras.vue";
import SystemBasiskategorien from "@/components/system/Basiskategorien.vue";
import SystemPreislisten from "@/components/system/Preislisten.vue";
import SystemPreislistenFiliale from "@/components/system/PreislistenFiliale.vue";
import SystemPreislisteNeu from "@/components/system/NewPreislisteFilialeNeu.vue";
import SystemPreislisteDetails from "@/components/system/PreislisteDetails.vue";
import SystemPreislisteEdit from "@/components/system/NewPreislisteEdit.vue";
import SystemKategorien from "@/components/system/Kategorien.vue";
import SystemOeffnungszeiten from "@/components/system/Oeffnungszeiten.vue";
import SystemOeffnungszeitenFiliale from "@/components/system/OeffnungszeitenFiliale.vue";
import SystemOeffnungszeitNeu from "@/components/system/OeffnungszeitNeu.vue";
import SystemOeffnungszeitEdit from "@/components/system/OeffnungszeitEdit.vue";
import SystemOeffnungszeitDetails from "@/components/system/OeffnungszeitDetails.vue";
import SystemOeffnungszeitAusnahmen from "@/components/system/OeffnungszeitAusnahmen.vue";
import SystemOeffnungszeitAusnahmenFiliale from "@/components/system/OeffnungszeitAusnahmenFiliale.vue";
import SystemTische from "@/components/system/Tische.vue";
import SystemTischeFiliale from "@/components/system/TischeFiliale.vue";
import SystemTischDetails from "@/components/system/TischDetails.vue";
import SystemAuswertung from "@/components/system/Auswertung.vue";
import SystemInhaltsstoffe from "@/components/system/Inhaltsstoffe.vue";
import SystemBestellverwaltung from "@/components/system/Bestellverwaltung.vue";
import SystemAccount from "@/components/system/Account.vue";
import SystemSystemAccounts from "@/components/system/SystemAccounts.vue";
import SystemSystemAccountNeu from "@/components/system/SystemAccountNeu.vue";
import SystemSystemAccountDetails from "@/components/system/SystemAccountDetails.vue";
import { Role } from "@/store/role";
import Restaurants from "@/components/Restaurants.vue";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "",
    component: UserPage,
    children: [
      {
        path: "",
        name: RouteNames.NEU.HOME,
        component: Homepage,
      },
      {
        path: "/restaurants",
        name: RouteNames.RESTAURANTS,
        component: Restaurants,
      },
      {
        path: "rechtliches/datenschutz",
        name: RouteNames.DATENSCHUTZ,
        component: Datenschutz,
      },

      {
        path: "rechtliches/agb",
        name: RouteNames.AGB,
        component: Agb,
      },
      {
        path: "rechtliches/impressum",
        name: RouteNames.IMPRESSUM,
        component: Impressum,
      },
      {
        path: "betrieb-anfragen",
        name: RouteNames.BETRIEB_ANFRAGEN,
        component: BetriebAnmeldenInfo,
      },
    ],
  },
  {
    path: "/login-mitarbeiter",
    name: RouteNames.LOGIN_MITARBEITER,
    component: LoginMitarbeiter,
  },
  {
    path: "/login-system",
    name: RouteNames.LOGIN_SYSTEM,
    component: LoginSystem,
  },
  {
    path: "/system",
    component: SystemPage,
    meta: {
      auth: true,
      range: Role.SYSTEM,
    },
    children: [
      {
        path: "",
        name: RouteNames.SYSTEM.PORTAL,
        component: SystemPortal,
      },
      {
        path: "account",
        name: RouteNames.SYSTEM.ACCOUNT,
        component: SystemAccount,
      },
      {
        path: "system-accounts",
        name: RouteNames.SYSTEM.SYSTEM_ACCOUNTS,
        component: SystemSystemAccounts,
      },
      {
        path: "system-account-neu",
        name: RouteNames.SYSTEM.SYSTEM_ACCOUNT_NEU,
        component: SystemSystemAccountNeu,
      },
      {
        path: "system-account-details/:username",
        name: RouteNames.SYSTEM.SYSTEM_ACCOUNT_DETAILS,
        component: SystemSystemAccountDetails,
      },
      {
        path: "betriebe",
        name: RouteNames.SYSTEM.BETRIEBE,
        component: Betriebe,
      },
      {
        path: "betrieb-neu/:anfrage",
        name: RouteNames.SYSTEM.BETRIEB_NEU,
        component: BetriebNeu,
      },
      {
        path: "betrieb-edit/:id",
        name: RouteNames.SYSTEM.BETRIEB_EDIT,
        component: BetriebEdit,
      },
      {
        path: "auswertung",
        name: RouteNames.SYSTEM.AUSWERTUNG,
        component: SystemAuswertung,
      },
      {
        path: "betrieb-anfragen",
        name: RouteNames.SYSTEM.ANFRAGEN,
        component: SystemBetriebAnfragen,
      },
      {
        path: "betrieb-anfrage-details/:id",
        name: RouteNames.SYSTEM.ANFRAGE_DETAILS,
        component: SystemBetriebAnfrageDetails,
      },
      {
        path: "setup",
        name: RouteNames.SYSTEM.SETUP,
        component: Setup,
      },
      {
        path: "betrieb/:id",
        component: BetriebDetails,
        children: [
          {
            path: "",
            name: RouteNames.SYSTEM.BETRIEB,
            component: BetriebPage,
          },
          {
            path: "filialen",
            name: RouteNames.SYSTEM.FILIALEN,
            component: SystemFilialen,
          },
          {
            path: "filiale-details/:filialeId",
            name: RouteNames.SYSTEM.FILIALE_DETAILS,
            component: SystemFilialeDetails,
          },
          {
            path: "filiale-neu",
            name: RouteNames.SYSTEM.FILIALE_NEU,
            component: SystemFilialeNeu,
          },
          {
            path: "filiale-edit/:id",
            name: RouteNames.SYSTEM.FILIALE_EDIT,
            component: SystemFilialeEdit,
          },
          {
            path: "mitarbeiter",
            name: RouteNames.SYSTEM.MITARBEITER,
            component: SystemMitarbeiter,
          },
          {
            path: "mitarbeiter-neu",
            name: RouteNames.SYSTEM.MITARBEITER_NEU,
            component: SystemMitarbeiterNeu,
          },
          {
            path: "mitarbeiter-details/:id",
            name: RouteNames.SYSTEM.MITARBEITER_DETAILS,
            component: SystemMitarbeiterDetails,
          },
          {
            path: "mitarbeiter-edit/:id",
            name: RouteNames.SYSTEM.MITARBEITER_EDIT,
            component: SystemMitarbeiterEdit,
          },
          {
            path: "produkte",
            name: RouteNames.SYSTEM.PRODUKTE,
            component: SystemProdukte,
          },
          {
            path: "produkt-neu",
            name: RouteNames.SYSTEM.PRODUKT_NEU,
            component: SystemProduktNeu,
          },
          {
            path: "produkt-details/:id",
            name: RouteNames.SYSTEM.PRODUKT_DETAILS,
            component: SystemProduktDetails,
          },
          {
            path: "produkt-extras",
            name: RouteNames.SYSTEM.PRODUKT_EXTRAS,
            component: SystemProduktExtras,
          },
          {
            path: "basiskategorien",
            name: RouteNames.SYSTEM.BASISKATEGORIEN,
            component: SystemBasiskategorien,
          },
          {
            path: "preislisten",
            name: RouteNames.SYSTEM.PREISLISTEN,
            component: SystemPreislisten,
          },
          {
            path: "preislisten-filiale/:filialeId",
            name: RouteNames.SYSTEM.PREISLISTEN_FILIALE,
            component: SystemPreislistenFiliale,
          },
          {
            path: "preisliste-neu/:filialeId",
            name: RouteNames.SYSTEM.PREISLISTE_NEU,
            component: SystemPreislisteNeu,
          },
          {
            path: "preisliste-details",
            name: RouteNames.SYSTEM.PREISLISTE_DETAILS,
            component: SystemPreislisteDetails,
          },
          {
            path: "preisliste-edit/:id",
            name: RouteNames.SYSTEM.PREISLISTE_EDIT,
            component: SystemPreislisteEdit,
          },
          {
            path: "kategorien",
            name: RouteNames.SYSTEM.KATEGORIEN,
            component: SystemKategorien,
          },
          {
            path: "oeffnungszeiten",
            name: RouteNames.SYSTEM.OEFFNUNGSZEITEN,
            component: SystemOeffnungszeiten,
          },
          {
            path: "oeffnungszeiten-filiale/:filialeId",
            name: RouteNames.SYSTEM.OEFFNUNGSZEITEN_FILIALE,
            component: SystemOeffnungszeitenFiliale,
          },
          {
            path: "oeffnungszeit-neu/:id",
            name: RouteNames.SYSTEM.OEFFNUNGSZEIT_NEU,
            component: SystemOeffnungszeitNeu,
          },
          {
            path: "oeffnungszeit-edit/:id",
            name: RouteNames.SYSTEM.OEFFNUNGSZEIT_EDIT,
            component: SystemOeffnungszeitEdit,
          },
          {
            path: "oeffnungszeit-details/:id",
            name: RouteNames.SYSTEM.OEFFNUNGSZEIT_DETAILS,
            component: SystemOeffnungszeitDetails,
          },
          {
            path: "oeffnungszeit-ausnahmen",
            name: RouteNames.SYSTEM.OEFFNUNGSZEIT_AUSNAHMEN,
            component: SystemOeffnungszeitAusnahmen,
          },
          {
            path: "oeffnungszeit-ausnahmen-filiale/:filialeId",
            name: RouteNames.SYSTEM.OEFFNUNGSZEIT_AUSNAHMEN_FILIALE,
            component: SystemOeffnungszeitAusnahmenFiliale,
          },
          {
            path: "tische",
            name: RouteNames.SYSTEM.TISCHE,
            component: SystemTische,
          },
          {
            path: "tische-filiale/:filialeId",
            name: RouteNames.SYSTEM.TISCHE_FILIALE,
            component: SystemTischeFiliale,
          },
          {
            path: "tisch-details/:id",
            name: RouteNames.SYSTEM.TISCH_DETAILS,
            component: SystemTischDetails,
          },
          {
            path: "inhaltsstoffe",
            name: RouteNames.SYSTEM.INHALTSSTOFFE,
            component: SystemInhaltsstoffe,
          },
          {
            path: "bestellverwaltung",
            name: RouteNames.SYSTEM.BESTELLVERWALTUNG,
            component: SystemBestellverwaltung,
          },
        ],
      },
    ],
  },
  {
    path: "/mitarbeiter",
    component: MemberPage,
    meta: {
      auth: true,
      range: Role.MITARBEITER,
    },
    children: [
      {
        path: "",
        name: RouteNames.MEMBER.PORTAL,
        component: MemberPortal,
      },
      {
        path: "filiale-select",
        name: RouteNames.MEMBER.FILIALE_SELECT,
        component: FilialeSelectMember,
      },
      {
        path: "kueche",
        name: RouteNames.MEMBER.KUECHE,
        component: Kueche,
      },
      {
        path: "service",
        name: RouteNames.MEMBER.SERVICE,
        component: Service,
      },
      {
        path: "eigene-daten",
        name: RouteNames.MEMBER.EIGENE_DATEN,
        component: EigeneDaten,
      },
      {
        path: "passwort-aendern",
        name: RouteNames.MEMBER.PASSWORT_AENDERN,
        component: PasswortAendern,
      },
      {
        path: "bestellungen",
        name: RouteNames.MEMBER.BESTELLUNGEN,
        component: AktuelleBestellungen,
      },
      {
        path: "bestellverwaltung",
        name: RouteNames.MEMBER.BESTELLLISTE,
        component: BestelllisteMember,
      },
      {
        path: "bestellung/:id",
        name: RouteNames.MEMBER.BESTELLUNG,
        component: BestellungMember,
      },
      {
        path: "reservierungen",
        name: RouteNames.MEMBER.RESERVIERUNGEN,
        component: Reservierungen,
      },
      {
        path: "filialen",
        name: RouteNames.MEMBER.FILIALEN,
        component: Filialen,
      },
      {
        path: "neue-filiale",
        name: RouteNames.MEMBER.FILIALE_NEU,
        component: FilialeNeu,
      },
      {
        path: "shortcuts",
        name: RouteNames.MEMBER.PDUKT_SHORTCUTS,
        component: Shortcuts,
      },
      {
        path: "filiale-details/:id",
        name: RouteNames.MEMBER.FILIALE_DETAILS,
        component: FilialeDetails,
      },
      {
        path: "filiale-liefergebiet-zuordnen/:id",
        name: RouteNames.MEMBER.FILIALE_LIEFERGEBIET_ZUORDNEN,
        component: FilialeLiefefergebietZuordnen,
      },
      {
        path: "filiale-edit/:id",
        name: RouteNames.MEMBER.FILIALE_EDIT,
        component: FilialeEdit,
      },
      {
        path: "produkte",
        name: RouteNames.MEMBER.PRODUKTE,
        component: Produkte,
      },
      {
        path: "produkt-details/:id",
        name: RouteNames.MEMBER.PRODUKT_DETAILS,
        component: ProduktDetails,
      },
      {
        path: "produkt-neu",
        name: RouteNames.MEMBER.PRODUKT_NEU,
        component: ProduktNeu,
      },
      {
        path: "produkt-edit/:id",
        name: RouteNames.MEMBER.PRODUKT_EDIT,
        component: ProduktEdit,
      },
      {
        path: "produkt-extras",
        name: RouteNames.MEMBER.PRODUKT_EXTRAS,
        component: ProduktExtras,
      },
      {
        path: "verfuegbarkeit",
        name: RouteNames.MEMBER.VERFUEGBARKEIT,
        component: Ausverkauft,
      },
      {
        path: "basiskategorien",
        name: RouteNames.MEMBER.BASISKATEGORIEN,
        component: Basiskategorien,
      },
      {
        path: "produktarten",
        name: RouteNames.MEMBER.PRODUKTARTEN,
        component: ProduktArten,
      },
      {
        path: "inhaltsstoffe",
        name: RouteNames.MEMBER.INHALTSSTOFFE,
        component: Inhaltsstoffe,
      },
      {
        path: "preislisten",
        name: RouteNames.MEMBER.PREISLISTEN,
        component: Preislisten,
      },
      {
        path: "preislisten-filiale/:filialeId",
        name: RouteNames.MEMBER.PREISLISTEN_FILIALE,
        component: PreislistenFiliale,
      },
      {
        path: "preisliste-details/:id",
        name: RouteNames.MEMBER.PREISLISTE_DETAILS,
        component: PreislisteDetails,
      },
      // {
      //   path: 'preisliste-neu',
      //   name: RouteNames.MEMBER.PREISLISTE_NEU,
      //   component: PreislisteNeu,
      // },
      {
        path: "preisliste-filiale-neu/:filialeId",
        name: RouteNames.MEMBER.PREISLISTE_FILIALE_NEU,
        component: PreislisteFilialeNeu,
      },
      {
        path: "preisliste-edit/:preislisteId",
        name: RouteNames.MEMBER.PREISLISTE_EDIT,
        component: PreislisteEdit,
      },
      {
        path: "tageskarte-neu/:filialeId",
        name: RouteNames.MEMBER.TAGESKARTE_NEU,
        component: TageskarteNeu,
      },
      {
        path: "tageskarte-edit/:preislisteId",
        name: RouteNames.MEMBER.TAGESKARTE_EDIT,
        component: TageskarteEdit,
      },
      {
        path: "tageskarte-details/:preislisteId",
        name: RouteNames.MEMBER.TAGESKARTE_DETAILS,
        component: TageskarteDetails,
      },
      {
        path: "kategorien",
        name: RouteNames.MEMBER.KATEGORIEN,
        component: Kategorien,
      },
      {
        path: "oeffnungszeiten",
        name: RouteNames.MEMBER.OEFFNUNGSZEITEN,
        component: Oeffnungszeiten,
      },
      {
        path: "oeffnungszeiten-filiale/:filialeId",
        name: RouteNames.MEMBER.OEFFNUNGSZEITEN_FILIALE,
        component: OeffnungszeitenFiliale,
      },
      {
        path: "oeffnungszeit-filiale/:filialeId",
        name: RouteNames.MEMBER.OEFFNUNGSZEIT_FILIALE,
        component: OeffnungszeitFiliale,
      },
      {
        path: "oeffnungszeit-details/:id",
        name: RouteNames.MEMBER.OEFFNUNGSZEIT_DETAILS,
        component: OeffnungszeitDetails,
      },
      {
        path: "oeffnungszeit-neu/:id",
        name: RouteNames.MEMBER.OEFFNUNGSZEIT_NEU,
        component: OeffnungszeitNeu,
      },
      {
        path: "oeffnungszeit-edit/:id",
        name: RouteNames.MEMBER.OEFFNUNGSZEIT_EDIT,
        component: OeffnungszeitEdit,
      },
      {
        path: "oeffnungszeit-ausnahmen-filiale/:filialeId",
        name: RouteNames.MEMBER.OEFFNUNGSZEIT_AUSNAHMEN_FILIALE,
        component: OeffnungszeitAusnahmenFiliale,
      },
      {
        path: "tische",
        name: RouteNames.MEMBER.TISCHE,
        component: Tische,
      },
      {
        path: "tische-filiale/:filialeId",
        name: RouteNames.MEMBER.TISCHE_FILIALE,
        component: TischeFiliale,
      },
      {
        path: "tisch-details/:id",
        name: RouteNames.MEMBER.TISCH_DETAILS,
        component: TischDetails,
      },
      {
        path: "tisch-edit/:tischId",
        name: RouteNames.MEMBER.TISCH_EDIT,
        component: TischEdit,
      },
      {
        path: "mitarbeiter",
        name: RouteNames.MEMBER.MITARBEITER,
        component: Mitarbeiter,
      },
      {
        path: "mitarbeiter-neu",
        name: RouteNames.MEMBER.MITARBEITER_NEU,
        component: MitarbeiterNeu,
      },
      {
        path: "mitarbeiter-details/:id",
        name: RouteNames.MEMBER.MITARBEITER_DETAILS,
        component: MitarbeiterDetails,
      },
      {
        path: "mitarbeiter-edit/:id",
        name: RouteNames.MEMBER.MITARBEITER_EDIT,
        component: MitarbeiterEdit,
      },
      {
        path: "auswertung",
        name: RouteNames.MEMBER.AUSWERTUNGEN,
        component: Auswertung,
      },
      {
        path: "lieferungen",
        name: RouteNames.MEMBER.LIEFERUNGEN,
        component: Lieferungen,
      },
      {
        path: "standardpreise",
        name: RouteNames.MEMBER.STANDARDPREISE,
        component: Standardpreise,
      },
      {
        path: "bestellung-aufnehmen",
        name: RouteNames.MEMBER.BESTELLUNG_AUFNEHMEN,
        component: BestellungAufnehmen,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some(
      (record) => record.meta.auth && record.meta.range == Role.MITARBEITER
    ) &&
    (!store.state.user.loggedIn || !(store.state.user.role == Role.MITARBEITER))
  ) {
    next("/login-mitarbeiter");
  } else if (
    to.matched.some(
      (record) => record.meta.auth && record.meta.range == Role.SYSTEM
    ) &&
    (!store.state.user.loggedIn || !(store.state.user.role == Role.SYSTEM))
  ) {
    next("/login-system");
  } else {
    next();
  }
});

export default router;
