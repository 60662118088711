
import Vue from "vue";
import Component from "vue-class-component";
import BasiskategorienLogic from "@/logic/member/basiskategorien_logic";
import BasiskategorienMemCommon from "@/components/memcommon/BasiskategorienMemCommon.vue";
import store from "@/store";

@Component({ components: { BasiskategorienMemCommon } })
export default class Basiskategorien extends Vue {
  logic?: BasiskategorienLogic = null;

  mounted() {
    this.logic = new BasiskategorienLogic();
    this.logic.betriebId = store.state.user.betriebId;
    this.logic.init();
  }
}
