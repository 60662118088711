import router from '@/router'
import RouteNames from '@/util/route_names'
import OeffnungszeitDetailsMemcommonLogic from '../memcommon/oeffnungszeit_details_memcommon_logic'

export default class OeffnungszeitDetailsLogic extends OeffnungszeitDetailsMemcommonLogic {
  ausnahmenHinzufuegen(): void {
    throw new Error('Method not implemented.')
  }
  
  listeBearbeiten(): void {
    router.push({
      name: RouteNames.SYSTEM.OEFFNUNGSZEIT_EDIT,
      params: { id: this.oeffnungszeit.filialeId },
    })
  }
}