export default class MitarbeiterInsertRequest {
  id?: string;
  betriebId?: string;
  filialeId?: string;
  nachname?: string;
  vorname?: string;
  geschlecht?;
  geburtsdatum?;
  strasse?: string;
  plz?: string;
  ort?: string;
  telefon?: string;
  telefon2?: string;
  email?: string;
  admin?: boolean;
  benutzername?: string;
  benutzerAnlegen = false;
  passwort?: string;
  passwortErzeugen = false;
  flush = false;
}
