import BestellungData from "@/util/bestellung_data";
import Vue from "vue";
import Vuex, { Store } from "vuex";
import VuexPersist from "vuex-persist";
import LoginResult from "@/entities/common/login_result";
import UserInfo from "./user_info";
import StoreOps from "@/util/store_ops";
import SocketMessage from "@/util/socket_message";
import Bestellung from "@/entities/common/bestellung";
import FilialeSimple from "@/entities/member/filiale_simple";

interface MangerooState {
  bestellung: BestellungData;
  incomingMessage: SocketMessage;
  user: UserInfo;
}

Vue.use(Vuex);

var vuexStore: Store<MangerooState> = null;

var cookiesEnabled = navigator.cookieEnabled;

if (cookiesEnabled) {
  var vuexLocalStorage = new VuexPersist({
    key: "vuex",
    storage: window.localStorage,
  });

  vuexStore = new Vuex.Store<MangerooState>({
    plugins: [vuexLocalStorage.plugin],
    state: (): MangerooState => ({
      bestellung: new BestellungData(),
      incomingMessage: null,
      user: new UserInfo(),
    }),
    mutations: {
      [StoreOps.FILIALE_SELECT_MEMBER](
        state: MangerooState,
        data: FilialeSimple
      ) {
        state.user.filiale = data;
      },
      [StoreOps.LOGIN_KUNDE](state: MangerooState, data: LoginResult) {
        state.user.loggedIn = true;
        state.user.vorname = data.vorname;
        state.user.nachname = data.nachname;
        state.user.username = data.username;
        state.user.role = data.role;
        state.user.key = data.key;
        state.user.betriebId = data.betriebId;
        state.user.admin = data.admin;
        state.user.expires = data.expires;
      },
      [StoreOps.LOGIN_MEMBER](state: MangerooState, data: LoginResult) {
        state.user.loggedIn = true;
        state.user.vorname = data.vorname;
        state.user.nachname = data.nachname;
        state.user.username = data.username;
        state.user.role = data.role;
        state.user.key = data.key;
        state.user.betriebId = data.betriebId;
        state.user.admin = data.admin;
        state.user.expires = data.expires;
      },
      [StoreOps.LOGOUT](state: MangerooState) {
        state.user.loggedIn = false;
        state.user.vorname = null;
        state.user.nachname = null;
        state.user.username = null;
        state.user.role = null;
        state.user.key = null;
        state.user.betriebId = null;
        state.user.admin = false;
        state.user.expires = null;
      },
      [StoreOps.POST_INCOMING_MESSAGE](
        state: MangerooState,
        payload: SocketMessage
      ) {
        state.incomingMessage = payload;
      },
      [StoreOps.BEGINNE_BESTELLUNG](state: MangerooState, payload: Bestellung) {
        state.bestellung = new BestellungData();
        state.bestellung.bestellung = payload;
      },
      [StoreOps.BEGINNE_VORBESTELLUNG](
        state: MangerooState,
        payload: Bestellung
      ) {
        state.bestellung = new BestellungData();
        state.bestellung.bestellung = payload;
      },
      [StoreOps.UPDATE_BESTELLUNG](state: MangerooState, payload: Bestellung) {
        state.bestellung.bestellung = payload;
      },
      [StoreOps.UPDATE_BESTELLUNG_ARTIKEL_BESTELLUNG](
        state: MangerooState,
        payload
      ) {
        state.bestellung.artikelliste = payload;
      },
      [StoreOps.UPDATE_IDENTIFIER](state: MangerooState, payload: string) {
        state.user.identifier = payload;
      },
    },
  });
}

export default vuexStore;
