
import ArtikelAktuell from '@/entities/common/artikel_aktuell'
import PlisteProduktZeit, {
  PreislisteProdukt,
} from '@/entities/common/pliste_produkt_zeit'
import RemoteResult from '@/entities/common/remote_result'
import PreislisteZeitraumRequest from '@/entities/common/request/preisliste_zeitpunkt_request'
import vuexStore from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import ExtrasContainer from '../custom/ExtrasContainer.vue'
import BstlArtikel from '@/entities/common/bstl_artikel'

@Component({ components: { ExtrasContainer } })
export default class BestellungAufnehmen extends mixins(Calculator) {
  logic: BestellungAufnehmenLogic = null

  mounted() {
    console.log('mounted')
    this.logic = new BestellungAufnehmenLogic()
    this.logic.init()
  }

  artikelHinzufuegen() {
    this.logic.artikelHinzufuegen()
  }
}

class BestellungAufnehmenLogic {
  preisliste: PlisteProduktZeit
  produktDialogVisible: boolean = false
  artikelAktuell: ArtikelAktuell = null
  warenkorb: BstlArtikel[] = []

  init() {
    var request = new PreislisteZeitraumRequest()
    request.filialeId = vuexStore.state.user.filiale.id

    Poster.postUser<RemoteResult<PlisteProduktZeit>>(
      RestPaths.artikelPdukteNachZeitSingle,
      request
    ).then((response) => {
      if (response.data.success) {
        this.preisliste = response.data.payload
      }
    })
  }

  artikelHinzufuegen() {
    var artikel = this.artikelAktuell.toArtikel()
    this.warenkorb.push(artikel)
    this.produktDialogVisible = false
  }

  produktWaehlen(produkt: PreislisteProdukt) {
    this.artikelAktuell = ArtikelAktuell.fromProdukt(produkt)
    this.produktDialogVisible = true
  }
}
