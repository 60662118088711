import PlisteSimple from '@/entities/member/pliste_simple'
import PreislistenFilialeMemcommonLogic from '../memcommon/preislisten_filiale_memcommon_logic'
import router from '@/router'
import RouteNames from '@/util/route_names'

export default class PreislistenFilialeLogic extends PreislistenFilialeMemcommonLogic {
  detailsAnzeigenPreisliste(p: PlisteSimple): void {
    throw new Error('Method not implemented.')
  }
  detailsAnzeigenTageskarte(p: PlisteSimple): void {
    throw new Error('Method not implemented.')
  }

  detailsAnzeigen(p: PlisteSimple): void {
    router.push({
      name: RouteNames.SYSTEM.PREISLISTE_DETAILS,
      params: { id: p.id },
    })
  }

  kategorienVerwalten(): void {
    router.push({ name: RouteNames.SYSTEM.KATEGORIEN })
  }

  neuePreisliste(): void {
    router.push({
      name: RouteNames.SYSTEM.PREISLISTE_NEU,
      params: { filialiId: this.filiale.id },
    })
  }

  neueTageskarte(): void {
    throw new Error('Method not implemented.')
  }
}