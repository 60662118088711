import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import Filiale from '@/entities/member/filiale'
import LiefergebietInsertListResponse from '@/entities/member/liefergebiet_insert_list_response'
import FilialeLiefergebieteInsertRequest, {
  Einzelgebiet,
  Einzelpunkt,
  Gebiet,
} from '@/entities/member/request/filiale_liefergebiete_insert_request'
import router from '@/router'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import MemberBaseLogic from '../member_base_logic'

export default class FilialeLiefergebietZuordnenMemcommonLogic extends MemberBaseLogic {
  liefergebiete?: LiefergebietInsertListResponse
  verfuegbareGebiete: VerfuegbaresPolygon[] = []
  gewaehlteGebiete: VerfuegbaresPolygon[] = []
  gebiete: Liefergebiet[] = []
  aktuellesGebiet: Liefergebiet = null
  filiale: Filiale = null
  kmlfile: any = null
  neuesLiefergebiet: Liefergebiet = new Liefergebiet()
  editLiefergebiet: Liefergebiet = new Liefergebiet()
  insertWorking: boolean = false
  insertCompleted: boolean = false
  insertFormDisabled: boolean = false
  insertError: boolean = false

  loadError: boolean = false

  init(id: string) {
    var request = new IdRequest()
    request.id = id

    Poster.postMember<RemoteResult<Filiale>>(RestPaths.filialeDetails, request)
      .then((response) => {
        if (response.data.success) {
          this.filiale = response.data.payload
        } else {
          this.loadError = true
        }
      })
      .catch(() => {
        this.loadError = true
      })
  }

  zuordnenBeginnen(gebiet: Liefergebiet) {
    this.aktuellesGebiet = gebiet
  }

  zuordnen() {
    this.gewaehlteGebiete.forEach((g) => {
      var poly = new Polygon()
      poly.bezeichnung = g.bezeichnung
      g.polygon.punkte.forEach((punkt) => {
        var point = new Point()
        point.latitude = punkt.latitude
        point.longitude = punkt.longitude
        poly.punkte.push(point)
      })
      this.aktuellesGebiet.gebiete.push(poly)
    })
  }

  uploadKmlFile() {
    var data = new FormData()
    data.append('file', this.kmlfile)

    fetch(RestPaths.urlBase + RestPaths.filialeParseKmlFile, {
      method: RestPaths.methodPost,
      body: data,
    })
      .then((response) => response.json())
      .then((data) => {
        this.liefergebiete = data.payload
        this.liefergebiete.gebiete.forEach((l) => {
          var vl = new VerfuegbaresPolygon()
          vl.bezeichnung = l.bezeichnung
          var vp = new Polygon()
          l.gebiet.points.forEach((p) => {
            var np = new Point()
            np.latitude = p.latitude
            np.longitude = p.longitude
            vp.punkte.push(np)
          })
          vl.polygon = vp
          this.verfuegbareGebiete.push(vl)
        })
      })
  }

  neuesLiefergebietSpeichern() {
    this.gebiete.push(this.neuesLiefergebiet)
    this.neuesLiefergebiet = new Liefergebiet()
  }

  liefergebietSpeichern() {
    this.editLiefergebiet = new Liefergebiet()
  }

  liefergebietBearbeiten(gebiet: Liefergebiet) {
    this.editLiefergebiet = gebiet
  }

  liefergebietEntfernen(index: number) {
    this.gebiete.splice(index, 1)
  }

  liefergebieteSpeichern() {
    var request = new FilialeLiefergebieteInsertRequest()
    request.filialeId = this.filiale.id

    this.gebiete.forEach((g) => {
      var gebiet = new Gebiet()
      gebiet.bezeichnung = g.bezeichnung
      gebiet.beschreibung = g.beschreibung
      gebiet.lieferkosten = g.lieferkosten
      gebiet.lieferzeitVon = g.lieferzeitVon
      gebiet.lieferzeitBis = g.lieferzeitBis
      gebiet.mindestbestellwert = g.mindestbestellwert
      gebiet.kostenlosAb = g.kostenlosAb
      gebiet.priority = g.priority

      g.gebiete.forEach((eg) => {
        var einzelgebiet = new Einzelgebiet()
        eg.punkte.forEach((pl) => {
          var einzelpunkt = new Einzelpunkt()
          einzelpunkt.latitude = pl.latitude
          einzelpunkt.longitude = pl.longitude
          einzelgebiet.punkte.push(einzelpunkt)
        })
        gebiet.gebiete.push(einzelgebiet)
      })
      request.gebiete.push(gebiet)
    })
    this.insertFormDisabled = true
    this.insertError = false
    this.insertWorking = true
    this.insertCompleted = false
    Poster.postMember<RemoteResult<Boolean>>(
      RestPaths.filialeLiefergebieteEinfuegen,
      request
    ).then((response) => {
      if (response.data.success) {
        this.insertCompleted = true
        this.insertWorking = false
      } else {
        this.insertCompleted = false
        this.insertWorking = false
        this.insertFormDisabled = false
        this.insertError = true
      }
    }).catch(()=> {
      this.insertWorking = false
      this.insertCompleted = false
      this.insertFormDisabled = false
      this.insertError = true
    })
  }

  navBack() {
    router.back()
  }
}

class VerfuegbaresPolygon {
  bezeichnung: string = ''
  polygon: Polygon = null
}

class Liefergebiet {
  bezeichnung: string = ''
  lieferkosten: number = 0
  lieferzeitVon: number = 0
  lieferzeitBis: number = 0
  mindestbestellwert: number = 0
  kostenlosAb: number = 0
  priority: number = 1
  gebiete: Polygon[] = []
  beschreibung?: string = null
}

class Polygon {
  bezeichnung: string = null
  punkte: Point[] = []
}

class Point {
  latitude: number = 0
  longitude: number = 0
}
