
import Component, { mixins } from "vue-class-component";
import Calculator from "../mixins/Calculator.vue";
import AusverkauftLogic from "@/logic/member/ausverkauft_logic";

@Component
export default class Ausverkauft extends mixins(Calculator) {
  $refs!: {
    datumMenu?: HTMLFormElement;
  };

  logic?: AusverkauftLogic = null;
  datumOpen: boolean = false;
  get hinzufuegenButtonDisabled() {
    return this.logic.datum == null || this.logic.filiale == null;
  }

  mounted() {
    this.logic = new AusverkauftLogic();
    this.logic.init();
  }

  ausverkauftLoeschen(a) {
    this.logic.ausverkauftLoeschen(a);
  }

  ausverkauftEinfuegen() {
    this.logic.ausverkauftEinfuegen();
  }

  updateVerfuegbarkeit() {
    this.logic.updateVerfuegbarkeit();
  }
}
