import RemoteResult from '@/entities/common/remote_result'
import Betrieb from '@/entities/member/betrieb'
import BetriebList from '@/entities/member/lists/betrieb_list'
import router from '@/router'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'

export default class BetriebeLogic {
  betriebe?: Betrieb[] = []

  detailsAnzeigen(betrieb: Betrieb) {
    router.push({
      name: RouteNames.SYSTEM.BETRIEB,
      params: { id: betrieb.id },
    })
  }

  neuerBetrieb() {
    router.push({ name: RouteNames.SYSTEM.BETRIEB_NEU })
  }

  init() {
    Poster.postMember<RemoteResult<BetriebList>>(
      RestPaths.betriebListe,
      {}
    ).then((response) => {
      this.betriebe = response.data.payload.list
    })
  }
}