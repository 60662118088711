import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from '@/store'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'

var VueCookie = require('vue-cookie')

Vue.config.productionTip = false
Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, { 
	locale: 'de-DE',
	decimalLength: 2,
	autoDecimalMode: false,
	min: 0,
	max: null,
	defaultValue: 0,
    valueAsInteger: false,
    allowNegative: false
})
Vue.use(VueCookie)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
