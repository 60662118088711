import FilialeSimple from '@/entities/member/filiale_simple'
import store from '@/store'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import RestPaths from '@/util/rest_paths'
import IdRequest from '@/entities/common/request/id_request'
import FilialeSimpleList from '@/entities/member/lists/filiale_simple_list'
import MitarbeiterNeuMemcommonLogic from '../memcommon/mitarbeiter_neu_memcommon_logic'

export default class MitarbeiterNeuLogic extends MitarbeiterNeuMemcommonLogic {
  get betriebId(): string {
    return store.state.user.betriebId
  }

  private _filialen: FilialeSimple[]

  get filialen(): FilialeSimple[] {
    return this._filialen
  }

  init() {
    var request = new IdRequest()
    request.id = this.betriebId

    Poster.postMember<RemoteResult<FilialeSimpleList>>(
      RestPaths.filialeListeSimple,
      request
    ).then((response) => {
      this._filialen = response.data.payload.list
    })
  }
}