
import router from '@/router'
import RouteNames from '@/util/route_names'
import Vue from 'vue'
import Component from 'vue-class-component'
import HomepageLogic from '@/logic/homepage_logic'

@Component
export default class Homepage extends Vue {
  mounted() {
  }

  ersteOptionWaehlen() {
    router.push({ name: RouteNames.BETRIEB_ANFRAGEN, params: { option: '1' } })
  }

  abgAnzeigen() {
    router.push({ name: RouteNames.AGB })
  }

  impressumAnzeigen() {
    router.push({ name: RouteNames.IMPRESSUM })
  }

  datenschutzAnzeigen() {
    router.push({ name: RouteNames.DATENSCHUTZ })
  }

  restaurantsAnzeigen() {
    router.push({ name: RouteNames.RESTAURANTS })
  }
}
