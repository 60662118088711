
import Vue from 'vue'
import StoreOps from '@/util/store_ops'
import RestPaths from '@/util/rest_paths'
import Component from 'vue-class-component'
import UserPageLogic from '@/logic/user_page_logic'
import store from '@/store'
import CookieLaw from 'vue-cookie-law'
import router from '@/router'
import RouteNames from '@/util/route_names'

@Component({ components: { CookieLaw } })
export default class UserPage extends Vue {
  logic?: UserPageLogic = null
  websocket = null

  mounted() {
    this.logic = new UserPageLogic()
    this.logic.init()
    this.websocket = new WebSocket(RestPaths.urlBaseWs + 'user')
    this.websocket.onopen = this.websocketOpened
    this.websocket.onmessage = this.websocketMessage
    this.websocket.onclose = this.websocketClosed
    this.websocket.onerror = this.websocketError
  }

  passwortVergessen() {
    this.logic.passwortVergessen()
  }

  logout() {
    this.logic.logout()
  }

  login() {
    this.logic.login()
  }

  neuRegistrieren() {
    this.logic.neuRegistrieren()
  }

  navHome() {
    this.logic.navHome()
  }

  showUserLogIn() {
    this.logic.showUserLogIn()
  }

  navBestellungen() {
    this.logic.navBestellungen()
  }

  navMitarbeiter() {
    this.logic.navMitarbeiter()
  }

  navBetriebAnfragen() {
    this.logic.navBetriebAnfragen()
  }

  navAgb() {
    router.push({ name: RouteNames.AGB })
  }

  navDatenschutz() {
    router.push({ name: RouteNames.DATENSCHUTZ })
  }

  navImpressum() {
    router.push({ name: RouteNames.IMPRESSUM })
  }

  websocketOpened() {
    this.websocket.send(store.state.user.identifier)
  }

  websocketMessage(message) {
    var parsedObject = JSON.parse(message.data)
    store.commit(StoreOps.POST_INCOMING_MESSAGE, parsedObject)
  }

  websocketClosed() {
    this.reopenService()
  }

  websocketError() {}

  reopenService() {
    setTimeout(() => {
      this.websocket = new WebSocket(RestPaths.urlBaseWs + 'user')
      this.websocket.onopen = this.websocketOpened
      this.websocket.onmessage = this.websocketMessage
      this.websocket.onclose = this.websocketClosed
      this.websocket.onerror = this.websocketError
    }, 5000)
  }
}
