
import BestellungFiliale from "@/entities/common/bestellung_filiale";
import BestellungFilialeList from "@/entities/common/lists/bestellung_filiale_list";
import RemoteResult from "@/entities/common/remote_result";
import Poster from "@/util/poster";
import RestPaths from "@/util/rest_paths";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class Restaurants extends Vue {
  logic: RestaurantsLogic = null;

  mounted() {
    this.logic = new RestaurantsLogic();
    this.logic.init();
  }

  computeBackgroundImage(filiale: BestellungFiliale) {
    return (
      "url(" +
      RestPaths.imageBasePath +
      filiale.logoKleinId +
      "_logo_klein.jpg)"
    );
  }
}

class RestaurantsLogic {
  filialen: BestellungFiliale[] = [];

  init() {
    Poster.postUser<RemoteResult<BestellungFilialeList>>(
      RestPaths.filialeListeAll,
      null
    ).then((response) => {
      if (response.data.success) {
        this.filialen = response.data.payload.list;
      }
    });
  }
}
