import Filiale from '@/components/custom/Filiale.vue'
import {
  Kategorie,
  KategorieZeitraum,
  Produkt,
  Subprodukt,
  ProduktExtra,
  Auswahl,
  Art,
  PreisArt,
  PreislisteProdukt,
  PreislisteProduktExtra,
  PreislisteProduktOption,
  PreislisteProduktAuswahl,
  PreislisteProduktSubprodukt,
} from '@/components/custom/preisliste'
import {
  Produkt as ReqProdukt,
  Auswahl as ReqAuswahl,
  Art as ReqArt,
  Extra as ReqExtra,
  Option as ReqOption,
  Subprodukt as ReqSubprodukt,
} from '@/entities/member/request/pliste_insert_request'

import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import NeuePreislisteInfo from '@/entities/member/neue_preisliste_info'
import PlisteInsertRequest from '@/entities/member/request/pliste_insert_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import { Produkt as NbdProdukt } from '@/entities/member/neue_preisliste_info'

export default class PreislisteNeuLogicHelper {
  tageskarte: boolean = false
  completed: boolean = false

  filiale: Filiale = null
  bezeichnung: string = null

  extras: Produkt[] = []
  produkte: Produkt[] = []

  init(filialeId: string) {
    console.log('init helper class')
    var request = new IdRequest()
    request.id = filialeId

    Poster.postMember<RemoteResult<Filiale>>(
      RestPaths.filialeDetails,
      request
    ).then((response) => {
      this.filiale = response.data.payload
    })

    var neueInfoRequest = new IdRequest()
    neueInfoRequest.id = filialeId

    Poster.postMember<RemoteResult<NeuePreislisteInfo>>(
      RestPaths.preislisteNeueInfo,
      neueInfoRequest
    ).then((response) => {
      console.log('neue data geladen')
      if (response.data.success) {
        let payload = response.data.payload

        payload.extras.forEach((e) => {
          var extra = new Produkt()
          extra.id = e.id
          extra.bezeichnung = e.bezeichnung
          extra.artikelNr = e.artikelNr
          extra.standardpreis = e.standardpreis

          e.arten.forEach((ea, i) => {
            var art = new Art()
            art.id = ea.id
            art.pduktArtId = ea.pduktArtId
            art.bezeichnung = ea.bezeichnung
            art.standardpreis = ea.standardpreis
            extra.arten.push(art)
          })
          this.extras.push(extra)
        })

        payload.produkte.forEach((p) => {
          console.log('produkt: ' + p.bezeichnung)
          var produkt = new Produkt()
          produkt.id = p.id
          produkt.bezeichnung = p.bezeichnung
          produkt.artikelNr = p.artikelNr
          produkt.standardpreis = p.standardpreis

          p.arten.forEach((a) => {
            var art = new Art()
            art.id = a.id
            art.pduktArtId = a.pduktArtId
            art.bezeichnung = a.bezeichnung
            art.standardpreis = a.standardpreis
            produkt.arten.push(art)
          })

          p.extras.forEach((e) => {
            var produktExtra = new ProduktExtra()
            var extra = this.findProduktExtra(e.pduktId)
            produktExtra.produkt = extra
            produktExtra.pduktExtraId = e.pduktExtraId
            produkt.extras.push(produktExtra)
          })

          p.auswahlen.forEach((a) => {
            var auswahl = new Auswahl()
            auswahl.id = a.id
            auswahl.bezeichnung = a.bezeichnung

            a.optionen.forEach((o) => {
              var produktExtra = new ProduktExtra()
              var option = this.findProduktExtra(o.pduktId)
              produktExtra.produkt = option
              produktExtra.pduktExtraId = o.auswahlOptionId
              auswahl.optionen.push(produktExtra)
            })
            produkt.auswahlen.push(auswahl)
          })

          p.subprodukte.forEach((sp) => {
            var subprodukt = new Subprodukt()
            subprodukt.id = sp.id
            subprodukt.bezeichnung = sp.bezeichnung
            produkt.subprodukte.push(subprodukt)
            console.log(subprodukt.bezeichnung)
            sp.produkte.forEach((spp) => {
              console.log(spp.bezeichnung)

              var produkt = new Produkt()
              produkt.id = spp.id
              produkt.bezeichnung = spp.bezeichnung
              produkt.artikelNr = spp.artikelNr
              produkt.variante = spp.variante
              produkt.varianteId = spp.varianteId

              spp.extras.forEach((e) => {
                var produktExtra = new ProduktExtra()
                var extra = this.findProduktExtra(e.pduktId)
                produktExtra.produkt = extra
                produktExtra.pduktExtraId = e.pduktExtraId
                produkt.extras.push(produktExtra)
              })

              spp.auswahlen.forEach((a) => {
                var auswahl = new Auswahl()
                auswahl.id = a.id
                auswahl.bezeichnung = a.bezeichnung

                a.optionen.forEach((o) => {
                  var produktExtra = new ProduktExtra()
                  var option = this.findProduktExtra(o.pduktId)
                  produktExtra.produkt = option
                  produktExtra.pduktExtraId = o.auswahlOptionId
                  auswahl.optionen.push(produktExtra)
                })
                produkt.auswahlen.push(auswahl)
              })

              subprodukt.optionen.push(produkt)
            })
          })

          this.produkte.push(produkt)
        })
      }
    })
  }

  findProduktExtra(id: string) {
    return this.extras.find((element) => element.id == id)
  }
}

function createInsertProdukt(
  prlProdukt: PreislisteProdukt,
  index: number
): ReqProdukt {
  var reqProdukt = new ReqProdukt()
  reqProdukt.produktId = prlProdukt.produkt.id
  reqProdukt.preis = prlProdukt.preis
  reqProdukt.index = index
  reqProdukt.shortcut = prlProdukt.shortcut

  prlProdukt.arten.forEach((art, index) => {
    var reqArt = new ReqArt()
    reqArt.pduktArtId = art.id
    reqArt.preis = art.preis
    reqArt.index = index
    reqProdukt.arten.push(reqArt)
  })

  prlProdukt.extras.forEach((prlExtra, index) => {
    var reqExtra = new ReqExtra()
    reqExtra.pduktExtraId = prlExtra.extra.pduktExtraId
    reqExtra.preis = prlExtra.preis
    reqExtra.index = index

    prlExtra.arten.forEach((art) => {
      var reqArt = new ReqArt()
      reqArt.pduktArtId = art.id
      reqArt.preis = art.preis
      reqExtra.arten.push(reqArt)
    })

    reqProdukt.extras.push(reqExtra)
  })

  prlProdukt.auswahlen.forEach((prlAuswahl, index) => {
    var reqAuswahl = new ReqAuswahl()
    reqAuswahl.auswahlId = prlAuswahl.auswahl.id
    reqAuswahl.index = index
    reqProdukt.auswahlen.push(reqAuswahl)

    prlAuswahl.optionen.forEach((prlOption, index) => {
      var reqOption = new ReqOption()
      reqOption.auswahlOptionId = prlOption.extra.pduktExtraId
      reqOption.preis = prlOption.preis
      reqOption.index = index

      prlOption.arten.forEach((art) => {
        var reqArt = new ReqArt()
        reqArt.pduktArtId = art.id
        reqArt.preis = art.preis
        reqOption.arten.push(reqArt)
      })

      reqAuswahl.optionen.push(reqOption)
    })
  })

  prlProdukt.subprodukte.forEach((prlSubprodukt, index) => {
    var reqSubprodukt = new ReqSubprodukt()
    reqSubprodukt.subproduktId = prlSubprodukt.subprodukt.id
    reqSubprodukt.index = index
    reqProdukt.subprodukte.push(reqSubprodukt)

    prlSubprodukt.optionen.forEach((prlOption, index) => {
      var reqSubproduktOption = new ReqProdukt()
      reqSubproduktOption.produktId = prlOption.produkt.id
      reqSubproduktOption.preis = prlOption.preis
      reqSubproduktOption.index = index
      reqSubproduktOption.artId = prlOption.produkt.varianteId

      reqSubprodukt.optionen.push(reqSubproduktOption)

      prlOption.extras.forEach((prlExtra, index) => {
        var reqExtra = new ReqExtra()
        reqExtra.pduktExtraId = prlExtra.extra.pduktExtraId
        reqExtra.preis = prlExtra.preis
        reqExtra.index = index
        reqSubproduktOption.extras.push(reqExtra)
      })

      prlOption.auswahlen.forEach((prlAuswahl, index) => {
        var reqAuswahl = new ReqAuswahl()
        reqAuswahl.auswahlId = prlAuswahl.auswahl.id
        reqAuswahl.index = index
        reqSubproduktOption.auswahlen.push(reqAuswahl)

        prlAuswahl.optionen.forEach((prlOption, index) => {
          var reqOption = new ReqOption()
          reqOption.auswahlOptionId = prlOption.extra.pduktExtraId
          reqOption.preis = prlOption.preis
          reqOption.index = index
          reqAuswahl.optionen.push(reqOption)
        })
      })
    })
  })

  return reqProdukt
}

function createProdukt(produkt: Produkt): PreislisteProdukt {
  var preislisteProdukt = new PreislisteProdukt()
  preislisteProdukt.produkt = produkt
  preislisteProdukt.hasArten = produkt.arten.length > 0

  if (produkt.arten.length > 0) {
    produkt.arten.forEach((a) => {
      var preislisteProduktArt = new PreisArt()
      preislisteProduktArt.id = a.pduktArtId
      preislisteProduktArt.bezeichnung = a.bezeichnung
      preislisteProduktArt.preis = a.standardpreis
      preislisteProdukt.arten.push(preislisteProduktArt)
    })
  }

  preislisteProdukt.preis = produkt.standardpreis
  produkt.extras.forEach((extra) => {
    var preislisteProduktExtra = new PreislisteProduktExtra()
    preislisteProduktExtra.extra = extra
    preislisteProduktExtra.preis = extra.produkt.standardpreis
    extra.produkt.arten.forEach((e) => {
      var preisArt = new PreisArt()
      preisArt.id = e.pduktArtId
      preisArt.bezeichnung = e.bezeichnung
      preisArt.preis = e.standardpreis
      preislisteProduktExtra.arten.push(preisArt)
    })

    preislisteProdukt.extras.push(preislisteProduktExtra)
  })

  produkt.auswahlen.forEach((auswahl) => {
    var preislisteProduktAuswahl = new PreislisteProduktAuswahl()
    preislisteProduktAuswahl.auswahl = auswahl

    auswahl.optionen.forEach((option) => {
      var preislisteProduktExtra = new PreislisteProduktExtra()
      preislisteProduktExtra.extra = option
      preislisteProduktExtra.preis = option.produkt.standardpreis

      option.produkt.arten.forEach((v) => {
        var preisArt = new PreisArt()
        preisArt.id = v.pduktArtId
        preisArt.bezeichnung = v.bezeichnung
        preisArt.preis = v.standardpreis
        preislisteProduktExtra.arten.push(preisArt)
      })

      preislisteProduktAuswahl.optionen.push(preislisteProduktExtra)
    })

    preislisteProdukt.auswahlen.push(preislisteProduktAuswahl)
  })

  produkt.subprodukte.forEach((subprodukt) => {
    var preislisteProduktSubprodukt = new PreislisteProduktSubprodukt()
    preislisteProduktSubprodukt.subprodukt = subprodukt
    subprodukt.optionen.forEach((suboption) => {
      var preislisteProduktOption = createProdukt(suboption)
      preislisteProduktSubprodukt.optionen.push(preislisteProduktOption)
    })
    preislisteProdukt.subprodukte.push(preislisteProduktSubprodukt)
  })
  return preislisteProdukt
}

function createNbdExtra(e: NbdProdukt): Produkt {
  var extra = new Produkt()
  extra.id = e.id
  extra.bezeichnung = e.bezeichnung
  extra.artikelNr = e.artikelNr
  extra.standardpreis = e.standardpreis

  e.arten.forEach((ea, i) => {
    var art = new Art()
    art.id = ea.id
    art.pduktArtId = ea.pduktArtId
    art.bezeichnung = ea.bezeichnung
    art.standardpreis = ea.standardpreis
    extra.arten.push(ea)
  })

  return extra
}

function createNbdProdukt(p: NbdProdukt, extras: Produkt[]): Produkt {
  var produkt = new Produkt()
  produkt.id = p.id
  produkt.bezeichnung = p.bezeichnung
  produkt.artikelNr = p.artikelNr
  produkt.standardpreis = p.standardpreis

  p.arten.forEach((a) => {
    var art = new Art()
    art.id = a.id
    art.pduktArtId = a.pduktArtId
    art.bezeichnung = a.bezeichnung
    art.standardpreis = a.standardpreis
    produkt.arten.push(art)
  })

  p.extras.forEach((e) => {
    var produktExtra = new ProduktExtra()
    var extra = findProduktExtra(e.pduktId, extras)
    produktExtra.produkt = extra
    produktExtra.pduktExtraId = e.pduktExtraId
    produkt.extras.push(produktExtra)
  })

  p.auswahlen.forEach((a)=> {
    var auswahl = new Auswahl()
    auswahl.id = a.id
    auswahl.bezeichnung = a.bezeichnung

    a.optionen.forEach((o)=> {
      var produktExtra = new ProduktExtra()
      var option = findProduktExtra(o.pduktId, extras)
      produktExtra.produkt = option
      produktExtra.pduktExtraId = o.auswahlOptionId
      auswahl.optionen.push(produktExtra)
    })
    produkt.auswahlen.push(auswahl)
  })

  p.subprodukte.forEach((sp)=> {
    var subprodukt = new Subprodukt()
    subprodukt.id = sp.id
    subprodukt.bezeichnung = sp.bezeichnung
    produkt.subprodukte.push(subprodukt)
    sp.produkte.forEach((spp) => {
      console.log(spp.bezeichnung)
      var produkt = new Produkt()
      produkt.id = spp.id
      produkt.bezeichnung = spp.bezeichnung
      produkt.artikelNr = spp.artikelNr
      produkt.variante = spp.variante
      produkt.varianteId = spp.varianteId

      spp.extras.forEach((e) => {
        var produktExtra = new ProduktExtra()
        var extra = findProduktExtra(e.pduktId, extras)
        produktExtra.produkt = extra
        produktExtra.pduktExtraId = e.pduktExtraId
        produkt.extras.push(produktExtra)
      })

      spp.auswahlen.forEach((a) => {
        var auswahl = new Auswahl()
        auswahl.id = a.id
        auswahl.bezeichnung = a.bezeichnung

        a.optionen.forEach((o)=> {
          var produktExtra = new ProduktExtra()
          var option = findProduktExtra(o.pduktId, extras)
          produktExtra.produkt = option
          produktExtra.pduktExtraId = o.auswahlOptionId
          auswahl.optionen.push(produktExtra)
        })
        produkt.auswahlen.push(auswahl)
      })
      subprodukt.optionen.push(produkt)
    })
  })

  return produkt
}

function findProduktExtra(id: string, extras: Produkt[]) {
  return extras.find((element) => element.id == id)
}

export { createInsertProdukt, createProdukt, createNbdExtra, createNbdProdukt }

